import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model } from '@/components/DsForm'
import { Column } from '@/components/DsTable'

const userModel: Model = {
  type: 'object',
  indent: false,
  properties: {
    email: {
      type: 'string',
      label: 'Email',
      format: 'email',
      required: true,
    },
    password: {
      type: 'string',
      label: 'Password',
      required: true,
      onlyOnCreate: true,
    },
    name: {
      type: 'string',
      label: 'Name',
      required: true,
    },
    lastname: {
      type: 'string',
      label: 'Last Name',
      required: true,
    },
    phone_number: {
      type: 'string',
      label: 'Phone',
      required: false,
    },
  },
}

const defaultValue = (): any => ({
  organization_id: '',
  name: '',
  lastname: '',
  email: '',
  password: '',
})

const columns: Column[] = [
  { key: 'name', header: 'Name' },
  { key: 'lastname', header: 'Last Name' },
  {
    key: 'organization_id',
    header: 'Organization id',
    formatter: (v: any) => (v ? v : '-'),
  },
  {
    key: 'created_at',
    header: 'Created at',
    formatter: (value) => {
      if (typeof value === 'string') {
        return new Date(value).toISOString().split('T')[0]
      }
      return '-'
    },
  },
]

export default defineComponent({
  name: 'Users',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'users',
          title: 'Users',
          model: userModel,
          columns: columns,
          createButtonLabel: 'New user',
          rowKey: 'id',
          history: false,
          searchBar: false,
        }}
        defaultValue={defaultValue}
      />
    )
  },
})
